// import { useState } from 'react'
// import NavBar from "../../components/NavBar/NavBar"
import InfoButton from "../../components/InfoButton/InfoButton"
import "./Testing.css"

export default function Testing() {
  return (
    // <div>
    //     <NavBar />
    // </div>
    <div>
      <InfoButton />
    </div>
  )
}
